import React, { useCallback, useState } from 'react';
import {
    Button,
    Container,
    Stack,
    TextField,
    Typography,
    FormControlLabel,
    Checkbox,
    CircularProgress,
    Link,
} from '@mui/material';
import Textarea from '@mui/joy/Textarea';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import axios from 'axios';
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const App: React.FC<{}> = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [isComplate, setComplate] = useState(false);
    const [hasError, setError] = useState(false)
    const [isSubmitting, setSubmitting] = useState(false);
    const onSubmit = useCallback((data) => {
        setSubmitting(true);
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
            if (key !== 'card' && key !== 'image') {
                formData.append(key, data[key]);
            } else {
                formData.append(key, data[key][0]);
            }
        });
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        axios.post('/product/create', formData, config).then((response) => {
            if (response.data.result) {
                setError(false)
                setComplate(true);
            } else {
                setError(true)
                setComplate(true);
            }
        });
    }, []);

    if (isComplate) {
        if (hasError) {
            return (
                <>
                    <Container maxWidth="sm" sx={{ pt: 5, marginBottom: 10 }}>
                        <Typography variant="body1">
                            申し込み中にエラーが発生しました。お手数をお掛けしますが竹花塾Discodeサーバーか<Link href="https://twitter.com/29_Nom">
                                こちら
                            </Link>までお問い合わせください。
                        </Typography>
                    </Container>
                </>
            );
        } else {
            return (
                <>
                    <Container maxWidth="sm" sx={{ pt: 5, marginBottom: 10 }}>
                        <Typography variant="body1">
                            申し込みを受け付けました。 添削受付状況は
                            <Link href="https://docs.google.com/spreadsheets/d/17wm3ZfOVIJ0wDWLGGlYajV4JSvVkBHkDhhyZGx6E-Jw/edit#gid=0">
                                こちら
                            </Link>
                            から確認いただけます。
                        </Typography>
                    </Container>
                </>
            );
        }
    } else {
        return (
            <>
                <Container maxWidth="sm" sx={{ pt: 5, marginBottom: 10 }}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Stack spacing={3}>
                            <Typography variant="h4" component="h4">
                                イラスト一言アドバイス応募フォーム
                            </Typography>
                            <Typography variant="body1">
                                竹花ノートによりイラスト添削の申込みフォームです。 必要事項に回答の上お申し込みください。
                                ご加入中のプランに関してpixivFANBOXの加入プランの状況がわかるスクショ画像を提出してください。
                                FANBOX→ <Link href="https://take-note.fanbox.cc/">https://take-note.fanbox.cc/</Link>
                            </Typography>
                            <TextField required label="作者名" {...register('name', { required: "必須です", minLength: 1 })} />
                            <TextField required
                                label="連絡先URL(twitterアカウント or PixivId)"
                                {...register('account', { required: "必須です", minLength: 1 })}
                            />
                            <FormControlLabel
                                control={<Checkbox {...register('describe')} />}
                                label="添削配信で名前とSNSの記載を希望する"
                            />
                            <TextField required label="作品タイトル" {...register('title')} />
                            {/* <TextField label="作成時間" {...register('cost')} />
                            <TextField label="作成ツール" {...register('tool')} />
                            <Textarea
                                minRows={3}
                                placeholder="特に見てもらいたい点、うまくなりたい点など(最大200文字前後)"
                                style={{ width: '100%' }}
                                {...register('point')}
                            />
                            <Textarea
                                minRows={3}
                                placeholder="コメント、目標など(最大200文字前後)"
                                style={{ width: '100%' }}
                                {...register('comment')}
                            />
                            <TextField label="目標の作家さん等" {...register('target')} /> */}

                            <Typography variant="body1">
                                ご加入中のプラン(10000円プランのみ）の加入状況がわかる画像
                            </Typography>
                            <input required {...register('card', { required: true })} type="file" accept=".png, .jpg, .jpeg" />
                            <Typography variant="body1">添削希望のイラスト</Typography>
                            <input required {...register('image', { required: "必須です", validate: (files) => files[0]?.size > 5242880 ? "添削用の画像は5MB以内でお願いします。" : undefined })} type="file" accept=".png, .jpg, .jpeg" />
                            <ErrorMessage errors={errors} name="image" as="p" style={{ color: "red" }} />
                            <Button
                                color="primary"
                                variant="contained"
                                size="large"
                                type="submit"
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            color: 'primary',
                                        }}
                                    />
                                ) : (
                                    '送信'
                                )}
                            </Button>
                        </Stack>
                    </form>
                </Container>
            </>
        );
    }
};

export default App;
